<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>
        <div>
          <i18n path="objective.text" tag="div" class="bsat__paragraph">
            <template #title>
              <strong>{{ $t("objective.title") }}</strong>
            </template>
          </i18n>
        </div>

        <!-- <ReadMore>
          <div class="bsat__paragraph">
            {{ $t("objective2") }}
          </div>

          <div class="bsat__paragraph">
            {{ $t("objective3") }}
          </div>
        </ReadMore> -->

        <div>
          <i18n path="action.text" tag="div" class="bsat__paragraph">
            <template #title>
              <strong>{{ $t("action.title") }}</strong>
            </template>
          </i18n>
        </div>

        <div>
          <i18n path="material.text" tag="div" class="bsat__paragraph">
            <template #title>
              <strong>{{ $t("material.title") }}</strong>
            </template>
          </i18n>
        </div>
      </div>
      <v-list dense three-line>
        <div v-for="(item, i) in currentCards" :key="i">
          <v-list-item class="card mb-4 px-0">
            <v-list-item-content>
              <v-list-item-title
                v-text="item.title"
                class="card-text font-weight-bold"
                style="font-family: Montserrat"
              ></v-list-item-title>
              <!-- <v-list-item-subtitle>
                <a :href="item.url" target="_blank">{{ item.url }}</a>
              </v-list-item-subtitle> -->
              <div
                style="
                  font-family: Montserrat;
                  font-size: 13px;
                  text-align: justify;
                "
                v-text="item.description"
              ></div>
              <a :href="item.url" target="_blank" class="mt-4">
                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  color="dark"
                >
                  {{ $t("visit") }}
                </v-btn>
              </a>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
      <v-row align="center">
        <!-- <div class="d-flex align-center">
          {{ $t("seeMoreTools") }} <v-icon small right>mdi-arrow-right</v-icon>
        </div>
        <v-spacer></v-spacer> -->
        <v-pagination
          class="pagination"
          color="dark"
          v-model="page"
          :length="3"
        ></v-pagination>
      </v-row>

      <i18n
        class="mt-8"
        tag="div"
        path="downloadAll.text"
        style="font-family: Montserrat"
      >
        <template #link>
          <a class="dark-text mt-8" :href="zip" target="_blank" download>
            {{ $t("downloadAll.link") }}
          </a>
        </template>
      </i18n>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "action": {
      "text": "{title}  Consult the list of selected existing tools. For each tool, you will find a link to access the site and a description of the main features so that you can select which can be of interest for your regional analysis. You are invited to consult the most interested tools in your case and collect the information generated as an input along the different phases of the methodology.",
      "title": "Action:"
    },
    "cards": [
      {
        "description": "S2BIOM is a compilation of all tools and documents generated in S2BIOM project, providing also access to all data and reports generated. S2BIOM project covers 37 European countries and an understanding of all aspects to be covered in a biomass delivery chain from biomass availability, logistics, conversion technologies, policies, markets and sustainability.",
        "keywords": [
          "Repository of bioeconomy tools"
        ],
        "title": "S2BIOM",
        "url": "https://www.s2biom.eu/"
      },
      {
        "description": "BERST toolkit supports getting an understanding of the bioeconomy potential and strategies of a range of different regions in Europe, and therefore to gain understanding of the possibilities and challenges related to the enhancement of biobased economies.",
        "keywords": [
          "Policies",
          "Strategies"
        ],
        "title": "BERST",
        "url": "https://berst.vito.be/about_berst"
      },
      {
        "description": "The tool provides information on biomass potentials and the key asset is that it supports the user to design a biomass chain at a detailed spatial scale (google maps scale). For the chain design it provides detailed cost and GHG emissions for different components of the chain.  The tool covers Croatia, France, Greece, Italy, Portugal, Slovenia, Spain and Turkey.",
        "keywords": [
          "Biomass",
          "biomass mapping"
        ],
        "title": "BIORAISE",
        "url": "http://bioraise.ciemat.es/Bioraise/home/main"
      },
      {
        "description": "S2BIOM is a compilation of all tools and documents generated in S2BIOM project, providing also access to all data and reports generated. S2BIOM project covers 37 European countries and an understanding of all aspects to be covered in a biomass delivery chain from biomass availability, logistics, conversion technologies, policies, markets and sustainability.This tool provides good practice examples for valorisation possibilities of agricultural and forestry industry sidestreams into a range of products. The tool allows filtering on country, subsector, process, technology readiness level (TRL), biomass sidestream or output (fuel, food, feed, fertiliser, fine chemical, functional material) and offers networking and marketing opportunities.",
        "keywords": [
          "Agricultural waste",
          "forestry",
          "best practises"
        ],
        "title": "AGRIFORVALOR",
        "url": "https://ec.europa.eu/eip/agriculture/en/find-connect/projects/agriforvalor-bringing-added-value-agriculture-and"
      },
      {
        "description": "Data of this portal consists of model outcomes and estimates which can be outlooks about future scenarios as well as calculations concerning the past to overcome the lack of official statistical data. Information mostly relates to the economics of agriculture and sustainable resources, resulting from scientific activities performed by the European Commission (in most cases by the Joint Research Centre - JRC) and partners. ",
        "keywords": [
          "Repository",
          "economic data in bioeconomy"
        ],
        "title": "DataM",
        "url": "http://datam.jrc.ec.europa.eu"
      },
      {
        "description": "BioESoil is the assessment tool with two main objectives: to estimate the impact of bio-energy production on soil organic matter and to raise awareness for nutrient recovery in bio-energy production.",
        "keywords": [
          "Bioenergy",
          "environmental impact"
        ],
        "title": "BioESoil",
        "url": "https://www.wur.nl/en/Research-Results/Research-Institutes/Environmental-Research/Facilities-Tools/Software-models-and-databases/BioESoil.htm"
      },
      {
        "description": "This is an Excel-based calculation tool and allows for operators to perform their own GHG calculations for bioenergy (electricity, heat and cooling), verifiers to check the calculations and Member States to monitor the greenhouse gas performance of subsidised plants. ",
        "keywords": [
          "Bioenergy",
          "environmental impact"
        ],
        "title": "BioGrace-II GHG ",
        "url": "https://www.biograce.net/biograce2/"
      },
      {
        "description": "The CFPAN tool and database FeedPrint calculates the carbon footprint of feed raw materials during their complete life cycle. This ranges from crop production, via processing of crop and animal products, compound feed production to utilization by the animal, including transport and storage between all steps of the production chain.",
        "keywords": [
          "Feed",
          "environmental impact"
        ],
        "title": "CFPAN tool and database FeedPrint",
        "url": "http://webapplicaties.wur.nl/software/feedprintNL/index.asp"
      },
      {
        "description": "The tool is designed to assist staff in the understanding of impacts from biomass bioenergy plant development over the whole life cycle of the technology and fuel chain, and to identify significant impacts that may occur from local proposals. Technologies covered are electricity (power plant), combined heat and power (CHP), heat only – (dedicated boilers burning biomass fuels to produce heat), cofiring, production of biodiesel and bioethanol and anaerobic digestion of animal and food waste to produce biogas which is then burnt to produce electricity and/or heat. ",
        "keywords": [
          "Bioenergy",
          "biomass",
          "environmental impact"
        ],
        "title": "BEAT2",
        "url": "https://www.forestresearch.gov.uk/tools-and-resources/biomass-energy-resources/reference-biomass/documents-downloads/beat/"
      }
    ],
    "downloadAll": {
      "link": "click here",
      "text": "*To download all the materials of Phase 1 as a zip file please {link}"
    },
    "material": {
      "text": "{title} List of selected existing tools for a complementary analysis of the regional bioeconomy potential",
      "title": "Material available:"
    },
    "objective": {
      "text": "{title} To complete the characterisation and mapping exercise  of your region using other existing tools different from the ones in Step 1. POWER4BIO assessed and selected the most interesting existing tools developed in the framework of other EU projects and initiatives according to the following criteria: content related to some aspect of bioeconomy, interactive and freely accessible. The selected tools cover a wide range of possible benefits, from general tools to very specific ones. It is strongly encouraged to nominate an expert to fulfil the most interesting tools in your case. This person would collect the requested information at regional level. Also, in some cases, the tools are of interest to other non-directly related stakeholders. Then, this regional representative would support other experts in filling in the surveys, enlarging the scope of the information collected and the conclusions of interest for the next steps. E.g., there are tools designed to help farmers select and move on into a more sustainable practices, fully aligned with bioeconomy principals. However, they might need the support of experts, for example, representatives from Academia as external supporters. The coordination and support is key to reach out and motivate the right user of the tools listed below, particularly, in those tools very detailed and sector specific.",
      "title": "Objective:"
    },
    "title": "Step 3: Complementary analysis with other existing tools",
    "seeMoreTools": "See more existing Tools by clicking on the right"
  }
}
</i18n>

<script>
import { getArrayPiece } from "@/utils/helpers";
// import ReadMore from "@/components/ReadMore";
export default {
  name: "Phase1Step3",
  components: {
    // ReadMore,
  },
  data() {
    return {
      page: 1,
      zip: require("@/assets/files/Phase1_Files.zip").default
      // currentCards: []
    };
  },
  computed: {
    cards() {
      return Object.values(this.$t("cards"));
    },
    currentCards() {
      return getArrayPiece(this.cards, this.page, 3);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";

.step3 {
  .v-list {
    background-color: $off_white;
  }

  .pagination {
    .v-pagination__item {
      background: $inactive_grey;
      min-width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      margin: 0px 3px;
      box-shadow: none !important;
    }
    .v-pagination__navigation {
      background: $inactive_grey;
      min-width: 18px !important;
      width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      box-shadow: none !important;
    }
    .v-pagination__item .v-pagination__item--active {
      box-shadow: none;
    }
    .mdi-chevron-left {
      font-size: 12px;
    }
    .mdi-chevron-right {
      font-size: 12px;
    }
  }

  .card {
    background-color: $off_white;
    font-family: $font-family;

    a {
      text-decoration: none;
      color: $dark !important;
      font-size: 12px;
    }

    .v-list-item__title {
      color: $light_green;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
    }
  }
  .examples {
    &-description {
      color: $dark;
      font-size: 13px;
      font-family: "Montserrat";
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: justify;
    }
  }
}
</style>
